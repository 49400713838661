<template>
    <section class="pt-3 overflow-auto custom-scroll pr-3" style="height:calc(100vh - 124px)">
        <div class="row mx-0">
            <div class="card-select">
                <div class="row mx-0">
                    <div class="col-12 px-0">
                        <p class="text-general pl-2 f-12 f-500">
                            Seleccionar fecha
                        </p>
                        <el-date-picker
                        v-model="fecha"
                        size="small"
                        class="w-100"
                        type="date"
                        format="dd MMM yyyy"
                        value-format="yyyy-MM-dd"
                        :clearable="false"
                        placeholder="Seleccione Fecha"
                        @change="loadDeliverys"
                        />
                    </div>
                    <div class="col-12 px-0 cant-deliverys text-general bg-white d-middle-center mt-3">
                        <b class="f-35">
                            {{ deliverys.length }}
                        </b>
                        <span class="mx-2 f-18 f-500">
                            Deliverys
                        </span>
                    </div>
                </div>
            </div>
            <div class="col">
                <div class="scrolling-wrapper row mx-0 flex-row flex-nowrap custom-scroll2 overflow-auto" style="max-width: calc(100vw - 324px);">
                    <div v-for="(d, key) in deliverys" :key="key" class="outbox-card mr-3" style="height:180px;">
                        <div :class="`card-delivery cr-pointer position-relative pt-3 ${deliveryActivo == d.id_delivery ? 'active-card' : ''}`" @click="setDelivery(d.id_delivery)">
                            <!-- count-pendientes -->
                            <el-tooltip placement="bottom" content="Pedidos pendientes" effect="light">
                                <div class="count text-general-red position-absolute bg-general f-14 p-1 d-middle-center">
                                    {{ d.pedidos_pendientes }}
                                </div>
                            </el-tooltip>
                            <el-popover
                            v-if="d.cierre_dia"
                            placement="right"
                            width="244"
                            popper-class="br-12"
                            trigger="hover"
                            >
                                <p class="f-13 text-general f-600 mb-2">Cierre del dia</p>
                                <p class="f-13 text-general">{{ d.cierre_dia.comentario }}</p>
                                <div class="row mx-0 mt-2">
                                    <img :src="d.cierre_dia.foto_admin" width="34" height="34" class="rounded-circle obj-cover" />
                                    <div class="col px-2">
                                        <p class="text-general f-13">{{ d.cierre_dia.responsable }}</p>
                                        <p class="text-general2 f-13">{{ d.cierre_dia.created_at | helper-fecha('DD MMM YYYY hh:mm a') }}</p>
                                    </div>
                                </div>
                                <i slot="reference" class="icon-ok-circled-outline text-green f-20 position-conf" />
                            </el-popover>
                            <div class="row mx-0 justify-center">
                                <img :src="d.foto" width="58" height="58" class="rounded-circle border obj-cover" />
                                <el-tooltip placement="bottom" :content="d.nombre" effect="light">
                                    <div class="col-12 text-center tres-puntos f-14 text-general f-500 mt-2">
                                        {{ d.nombre }}
                                    </div>
                                </el-tooltip>
                                <div class="col-12 mt-2">
                                    <div class="progress-card">
                                        <el-popover
                                        placement="bottom"
                                        trigger="hover"
                                        width="262"
                                        >
                                            <p class="text-general f-13 text-center f-600 mb-2">
                                                Uso de app para registrar pagos
                                            </p>
                                            <div class="row mx-0">
                                                <div style="background-color:#28D07B;width:12px;height:12px;" />
                                                <div class="col px-2 text-general f-13">
                                                    {{ d.uso_app }}% de los pedidos registrados
                                                </div>
                                            </div>
                                            <div slot="reference" class="progress-edit" :style="`width:${d.uso_app}%`" />
                                        </el-popover>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div v-show="deliveryActivo == d.id_delivery && d.cierre_dia == null && $can('botones_tesoreria_deliverys')" class="conf-delivery mt-2 text-white f-15 d-middle-center cr-pointer" @click="$refs.modalCerrarDia.toggle()">
                            Cerrar Dia
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row mx-0 my-2">
            <div class="col-12 bg-white br-8 d-flex py-3" style="height:111px;">
                <div class="col-2 border-right text-center text-general py-2">
                    <p class="f-25 f-600">{{ totales.cantidad }}</p>
                    <p class="f-14 text-general">Pedidos</p>
                </div>
                <div class="col border-right text-center text-general py-2">
                    <p class="f-25 f-600">{{ convertMoney(totales.efectivo) }}</p>
                    <p class="f-14 text-general">Dinero en efectivo</p>
                </div>
                <div class="col border-right text-center text-general py-2">
                    <p class="f-25 f-600">{{ convertMoney(totales.transferencia) }}</p>
                    <p class="f-14 text-general">Dinero por transferencia</p>
                </div>
                <div class="col border-right text-center text-general py-2">
                    <p class="f-25 f-600">{{ convertMoney(totales.credito) }}</p>
                    <p class="f-14 text-general">Crédito otorgados</p>
                </div>
                <div class="col border-right text-center text-general py-2">
                    <p class="f-25 f-600">{{ convertMoney(totales.descartados) }}</p>
                    <p class="f-14 text-general">En pedidos descartados</p>
                </div>
                <div class="col text-center text-general py-2">
                    <p class="f-25 f-600">{{ convertMoney(totales.aplicados) }}</p>
                    <p class="f-14 text-general">En pedidos aplicados</p>
                </div>
            </div>
        </div>
        <div class="row mx-0 py-2" />
        <div class="row mx-0 mb-3">
            <div class="col-12 bg-white py-4 text-general f-17 br-t-8">
                <div class="row mx-0 align-items-end">
                    <div class="col-auto px-0 text-general f-500 f-17 pb-1">
                        Pedidos pagados en efectivo
                    </div>
                    <div class="col-auto f-24 text-general f-500 ml-auto">
                        {{ convertMoney(efectivoTotal) }}
                    </div>
                    <div v-if="$can('botones_tesoreria_deliverys')" class="btn-outline px-2 f-14" @click="validarDescarte(1)">
                        Descartar pedidos
                    </div>
                    <div v-if="$can('botones_tesoreria_deliverys')" class="btn-general mx-2 f-14 px-3" @click="validarPago(1)">
                        Aplicar pagos
                    </div>
                </div>
            </div>
            <tabla-pedidos ref="tablaEfectivo" :pedidos="pedidosEfectivo" :comisiones="comisiones" :cajas="cajas" :tipo-caja="1" efectivo />
        </div>
        <div class="row mx-0">
            <div class="col-12 bg-white py-4 text-general f-17 br-t-8">
                <div class="row mx-0 align-items-end">
                    <div class="col-auto px-0 text-general f-500 f-17 pb-1">
                        Pedidos pagados por transferencia
                    </div>
                    <div class="col-auto f-24 text-general f-500 ml-auto">
                        {{ convertMoney(transferenciaTotal) }}
                    </div>
                    <div v-if="$can('botones_tesoreria_deliverys')" class="btn-outline px-2 f-14" @click="validarDescarte(2)">
                        Descartar pedidos
                    </div>
                    <div v-if="$can('botones_tesoreria_deliverys')" class="btn-general mx-2 f-14 px-3" @click="validarPago(2)">
                        Aplicar pagos
                    </div>
                </div>
            </div>
            <tabla-pedidos ref="tablaTransferencia" :pedidos="pedidosTransferencia" :comisiones="comisiones" :cajas="cajas" :tipo-caja="2" transferencia />
        </div>
        <div class="row mx-0">
            <div class="col-12 bg-white py-4 text-general f-17 br-t-8">
                <div class="row mx-0 align-items-end">
                    <div class="col-auto px-0 text-general f-500 f-17 pb-1">
                        Pedidos con crédito otorgado
                    </div>
                    <div class="col-auto f-24 text-general f-500 ml-auto">
                        {{ convertMoney(creditoTotal) }}
                    </div>
                    <div v-if="$can('botones_tesoreria_deliverys')" class="btn-outline px-2 f-14" @click="validarDescarte(3)">
                        Descartar pedidos
                    </div>
                    <div v-if="$can('botones_tesoreria_deliverys')" class="btn-general mx-2 f-14 px-3" @click="validarPago(3)">
                        Aplicar pagos
                    </div>
                </div>
            </div>
            <tabla-pedidos ref="tablaCredito" :pedidos="pedidosCredito" :comisiones="comisiones" credito />
        </div>
        <div class="row mx-0">
            <div class="col-12 bg-white py-4 text-general f-17 br-t-8">
                <div class="row mx-0 align-items-end">
                    <div class="col-auto px-0 text-general f-500 f-17 pb-1">
                        Pedidos descartados
                    </div>
                    <div class="col-auto f-24 text-general f-500 ml-auto">
                        {{ convertMoney(descartadosTotal) }}
                    </div>
                    <div v-if="$can('botones_tesoreria_deliverys')" class="btn-general mx-2 f-14 px-3" @click="openModalRevertir()">
                        Revertir descarte
                    </div>
                </div>
            </div>
            <tabla-pedidos ref="tablaDescartados" :pedidos="pedidosDescartados" :comisiones="comisiones" descartados :colum-credito="false" />
        </div>
        <div class="row mx-0">
            <div class="col-12 bg-white py-4 text-general f-17 br-t-8">
                <div class="row mx-0 align-items-end">
                    <div class="col-auto px-0 text-general f-500 f-17 pb-1">
                        Pedidos aplicados
                    </div>
                    <div class="col-auto f-24 text-general f-500 ml-auto">
                        {{ convertMoney(aplicadosTotal) }}
                    </div>
                </div>
            </div>
            <tabla-pedidos ref="tablaAplicados" :pedidos="pedidosAplicados" :comisiones="comisiones" :selection="false" :colum-credito="false" aplicados />
        </div>
        <div class="row mx-0 mb-3">
            <div class="col-12 bg-white py-4 text-general f-17 br-t-8">
                <div class="row mx-0 align-items-end">
                    <div class="col-auto px-0 text-general f-500 f-17 pb-1">
                        Pedidos sin procesar
                    </div>
                    <div class="col-auto f-24 text-general f-500 ml-auto">
                        {{ convertMoney(sinProcesarTotal) }}
                    </div>
                </div>
            </div>
            <tabla-pedidos ref="tablaSinProcesar" :pedidos="pedidosSinProcesar" :comisiones="comisiones" :selection="false" :colum-credito="false" sinprocesar />
        </div>
        <!-- Partials -->
        <modal ref="modalAplicarCambio" titulo="Aplicar pago" no-aceptar adicional="Aplicar" @adicional="aplicarPago">
            <div class="row mx-0 justify-center">
                <div class="col-10 text-general text-center">
                    {{ mensaje }}
                </div>
            </div>
        </modal>
        <modal ref="modalRevertir" titulo="Revertir Descartados" no-aceptar adicional="Revertir" @adicional="cambiarEstadoTesoreria(1)">
            <div class="row mx-0 justify-center">
                <div class="col-10 text-general text-center">
                    ¿Desea revertir el descarte a los pedidos seleccionados?
                </div>
            </div>
        </modal>
        <modal ref="modalDescartar" titulo="Descartar Pedidos" no-aceptar adicional="Descartar" @adicional="cambiarEstadoTesoreria(2)">
            <div class="row mx-0 justify-center">
                <div class="col-10 text-general text-center">
                    ¿Realmente desea descartar el reporte de los pedidos seleccionados?
                </div>
            </div>
        </modal>
        <modal ref="modalCerrarDia" titulo="Cerrar Dia" no-aceptar adicional="Finalizar" @adicional="cerrarDiaDelivery">
            <div class="row mx-0 justify-center">
                <div class="col-10 text-general text-center">
                    ¿Desea cerrar el dia para este delivery?
                </div>
                <div class="col-10 text-general text-center mt-2">
                    <el-input v-model="comentario" type="textarea" :rows="5" maxlength="750" placeholder="Por favor digite un comentario para finalizar..." />
                </div>
            </div>
        </modal>
    </section>
</template>

<script>
import Delivery from '~/services/tesoreria/gestionDelivery'
import moment from 'moment'
export default {
    components: {
        tablaPedidos: () => import('./components/tablaPedidos'),
    },
    data(){
        return {
            comentario:'',
            deliveryActivo: null,
            fecha:null,
            deliverys:[],

            pedidosEfectivo:[],
            efectivoTotal:0,
            pedidosTransferencia:[],
            transferenciaTotal:0,

            pedidosCredito:[],
            creditoTotal:0,
            pedidosAplicados:[],
            aplicadosTotal:0,

            pedidosDescartados:[],
            descartadosTotal:0,

            pedidosSinProcesar: [],
            sinProcesarTotal: 0,

            comisiones:[],
            cajas:[],
            mensaje:'',
            tipoPago:0,
            idsCambioEstado:[],

            totales:{cantidad:0, efectivo:0, transferencia:0,credito:0,descartados:0,aplicados:0}

        }
    },
    mounted(){
        this.loadDeliverys()
    },
    methods: {
        async loadDeliverys(){
            try {
                if(this.fecha === null){
                    this.fecha = moment().format('YYYY-MM-DD')
                }
                this.resetData()
                const {data} = await Delivery.getLista(this.fecha)
                this.deliverys = data.deliverys

            } catch (error){
                this.error_catch(error)
            }
        },
        async setDelivery(idDelivery){
            try {
                this.deliveryActivo = idDelivery
                if(!idDelivery || !this.fecha) return
                await this.getSelectGenerales()
                const {data} = await Delivery.getPagosDelivery(idDelivery, this.fecha)
                this.comentario = ''
                this.pedidosEfectivo = data.pedidos_efectivo
                this.pedidosTransferencia = data.pedidos_transferencia
                console.log(this.pedidosTransferencia);
                this.pedidosCredito = data.pedidos_credito
                this.pedidosAplicados = data.pedidos_aplicados
                this.pedidosDescartados = data.pedidos_descartados
                this.totales = data.totales

                this.efectivoTotal = data.efectivo_total
                this.transferenciaTotal = data.transferencia_total
                this.creditoTotal = data.credito_total
                this.aplicadosTotal = data.aplicados_total
                this.descartadosTotal = data.descartados_total

                this.pedidosSinProcesar = data.pedidos_sin_procesar;
                this.sinProcesarTotal = data.sin_procesar_total;

            } catch (error){
                this.error_catch(error)
            }
        },
        async getTotales(){
            const {data} = await Delivery.getTotales(this.deliveryActivo , this.fecha)
            this.totales = data
        },
        async getSelectGenerales(){
            try {
                const {data} = await Delivery.getSelectGenerales()
                this.comisiones = data.comisiones
                this.cajas = data.cajas

            } catch (error){
                this.error_catch(error)
            }
        },
        async aplicarPago(){
            try {
                const form  = {tipo:this.tipoPago, pedidos:this.pedidosGestion}
                const {data} = await Delivery.aplicarPago(form)


                let tipos

                switch (this.tipoPago){
                case 1:
                    tipos = [11,12]

                    this.getPagosDeliveryXTipo(tipos)

                    break;
                case 2:
                    tipos = [21,22]
                    this.getPagosDeliveryXTipo(tipos)
                    break;
                case 3:
                    tipos = [31]
                    this.getPagosDeliveryXTipo(tipos)
                    break;

                default:
                    break;
                }

                this.getPedidosAplicados()
                this.$refs.modalAplicarCambio.toggle();


            } catch (error){
                this.error_catch(error)
            }
        },
        async getPagosDeliveryXTipo(tipo){

            const params = {
                tipo:tipo,
                fecha:this.fecha
            }

            const {data} = await Delivery.getPagosDeliveryXTipo(this.deliveryActivo, params)

            switch (this.tipoPago){
            case 1:
                this.pedidosEfectivo = data.pedidos
                this.efectivoTotal = data.total


                break;
            case 2:
                this.pedidosTransferencia = data.pedidos
                this.transferenciaTotal = data.total

                break;
            case 3:
                this.pedidosCredito = data.pedidos
                this.creditoTotal = data.total

                break;

            default:
                break;
            }
        },
        async getPedidosAplicados(){
            const params = {
                tipo:[3],
                fecha:this.fecha
            }
            const {data} = await Delivery.getPagosDeliveryAplicados(this.deliveryActivo, params)
            this.pedidosAplicados = data.pedidos
            this.aplicadosTotal = data.total
            this.getTotales()

        },
        async getPedidosDescartados(){
            const params = {
                tipo:[2],
                fecha:this.fecha
            }
            const {data} = await Delivery.getPagosDeliveryAplicados(this.deliveryActivo, params)
            this.pedidosDescartados = data.pedidos
            this.descartadosTotal = data.total
            this.getTotales()

        },

        validarPago(tipo){
            let pedidos = []
            this.pedidosGestion = []

            this.tipoPago = tipo

            switch (tipo){
            case 1:

                pedidos = this.$refs.tablaEfectivo.getSelection()
                this.mensaje = '¿Desea aplicar pago a todos los pedidos "pagados en efectivo"?'

                break;
            case 2:
                pedidos = this.$refs.tablaTransferencia.getSelection()
                this.mensaje = '¿Desea aplicar pago a todos los pedidos "pagados por transferencia"?'
                break;
            case 3:
                pedidos = this.$refs.tablaCredito.getSelection()
                this.mensaje = '¿Desea aplicar pago a todos los pedidos con crédito otorgado?'
                break;

            default:
                break;
            }


            if(pedidos.length === 0){
                this.notificacion("Advertencia",'Por favor seleccione los pedidos a los que desea aplicar el pago','warning')
                return
            }

            // console.log(pedidos);

            for (const p of pedidos){

                if(p.id_caja == null && tipo != 3){
                    this.notificacion("Advertencia",'Por favor seleccione la caja o la cuenta a la cual ingresara el pago','warning')
                    return
                }

                this.pedidosGestion.push({
                    id:p.id,
                    dias_credito:p.dias_credito,
                    id_caja:p.id_caja,
                    estado_comision:p.estado_comision
                })
            }

            this.$refs.modalAplicarCambio.toggle();
        },
        openModalRevertir(){

            let pedidos = this.$refs.tablaDescartados.getSelection()

            if(pedidos.length === 0){
                this.notificacion("Advertencia",'Por favor seleccione los pedidos a los que desea revertir el descarte','warning')
                return
            }

            this.idsCambioEstado = _.map(pedidos, 'id');

            this.$refs.modalRevertir.toggle();
        },
        async cambiarEstadoTesoreria(estado){
            try {
                const form = {pedidos:this.idsCambioEstado,estado}
                const {data} = await Delivery.cambiarEstadoTesoreria(form)
                this.notificacion("",'Se actualizado el estado de los pedidos','success')

                if(estado === 1){
                    this.setDelivery(this.deliveryActivo)
                    this.$refs.modalRevertir.toggle();
                }
                if(estado === 2){

                    let tipos

                    switch (this.tipoPago){
                    case 1:
                        tipos = [11,12]

                        this.getPagosDeliveryXTipo(tipos)

                        break;
                    case 2:
                        tipos = [21,22]
                        this.getPagosDeliveryXTipo(tipos)
                        break;
                    case 3:
                        tipos = [31]
                        this.getPagosDeliveryXTipo(tipos)
                        break;

                    default:
                        break;
                    }

                    this.$refs.modalDescartar.toggle();
                    this.getPedidosDescartados()
                }


            } catch (error){
                this.error_catch(error)
            }
        },
        validarDescarte(tipo){
            let pedidos = []
            this.tipoPago = tipo
            this.idsCambioEstado = []

            switch (tipo){
            case 1:
                pedidos = this.$refs.tablaEfectivo.getSelection()
                break;
            case 2:
                pedidos = this.$refs.tablaTransferencia.getSelection()
                break;
            case 3:
                pedidos = this.$refs.tablaCredito.getSelection()
                break;

            default:
                break;
            }


            if(pedidos.length === 0){
                this.notificacion("Advertencia",'Por favor seleccione los pedidos que desea descartar','warning')
                return
            }

            this.idsCambioEstado = _.map(pedidos, 'id');

            this.$refs.modalDescartar.toggle();
        },
        async cerrarDiaDelivery(){
            try {
                const form = {
                    id_delivery:this.deliveryActivo,
                    fecha:this.fecha,
                    comentario:this.comentario
                }
                const {data} = await Delivery.cerrarDiaDelivery(form)

                let delivery = this.deliverys.find(o=>o.id_delivery === this.deliveryActivo)
                delivery.cierre_dia = data.data

                this.$refs.modalCerrarDia.toggle()

            } catch (error){
                this.error_catch(error)
            }
        },
        resetData(){
            this.deliveryActivo = null,
            this.comentario = ''
            this.pedidosEfectivo = []
            this.pedidosTransferencia = []
            this.pedidosCredito = []
            this.pedidosAplicados = []
            this.pedidosDescartados =[]
            this.totales = {cantidad:0, efectivo:0, transferencia:0,credito:0,descartados:0,aplicados:0}
            this.efectivoTotal = 0
            this.transferenciaTotal = 0
            this.creditoTotal = 0
            this.aplicadosTotal = 0
            this.descartadosTotal = 0
        }
    }
}
</script>
<style lang="scss" scoped>
.card-select{
    width: 177px;
}
.cant-deliverys{
    border: 1px solid #DBDBDB;
    border-radius: 6px;
    height: 70px;
}
.card-delivery{
    width: 171px;
    height: 135px;
    border-radius: 8px;
    background-color: #FFFFFF;
    &.active-card{
        border: 1px solid var(--color-general);
        box-shadow: 0px 3px 6px #00000029;
    }
    .count{
        height: 24px;
        min-width: 24px;
        color: #FFFFFF;
        border-radius: 20px;
        right: 11px;
        top: 8px;
    }
    .position-conf{
        position: absolute;
        right: 9px;
        top: 41px;
    }
    .progress-card{
        height: 15px;
        border-radius: 2px;
        background-color: #DBDBDB;
        .progress-edit{
            background-color: #28D07B;
            border-radius: 2px;
            height: 100%;
        }
    }
}
.conf-delivery{
    width: 172px;
    height: 32px;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 8px;
    background-color: var(--color-general) !important;
}
.text-green{
    color: #28D07B;
}

</style>
