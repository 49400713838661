import axios from "axios";

const API = "tesoreria";

const Delivery = {
    getLista(fecha){
        return axios(`${API}/deliverys`, { params:{fecha} })
    },
    getPagosDelivery(idDelivery, fecha){
        return axios(`${API}/delivery/${idDelivery}/pagos`, { params:{fecha} })
    },
    getSelectGenerales(){
        return axios(`${API}/delivery/selects`)
    },
    setPedido(idPedido){
        return axios(`pedidos-admin/${idPedido}/get/tesoreria/delivery`)
    },
    aplicarPago(form){
        return axios.put(`${API}/delivery/set/pagos`,form)
    },
    cambiarEstadoTesoreria(form){
        return axios.put(`${API}/set/estado`,form)
    },
    getPagosDeliveryXTipo(idDelivery, params){
        return axios(`${API}/delivery/${idDelivery}/pagos/por-tipo`, { params })
    },
    getPagosDeliveryAplicados(idDelivery, params){
        return axios(`${API}/delivery/${idDelivery}/pagos/aplicados`, { params })
    },
    getPagosDeliveryDescartados(idDelivery, params){
        return axios(`${API}/delivery/${idDelivery}/pagos/descartados`, { params })
    },
    getTotales(idDelivery, fecha){
        return axios(`${API}/delivery/${idDelivery}/totales`, { params:{fecha} })
    },
    cerrarDiaDelivery(form){
        return axios.post(`${API}/delivery/cerrar-dia`, form)
    },

};

export default Delivery;
